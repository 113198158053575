footer {
  padding-top: 50PX;
  .f1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16PX;
      color: #000000;
      line-height: 22PX;
      letter-spacing: 1PX;
      display: flex;
      align-items: center;
      ul {
        margin-left: 70px;
        display: flex;
        list-style: none;
      }
      li + li {
        margin-left: 40PX;
      }
    }
    .right {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16PX;
      color: #666666;
      line-height: 22PX;
      letter-spacing: 1PX;
      display: flex;
      div {
        margin-right: 29px;
      }
    }
  }
  .f2 {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    line-height: 20PX;
    text-align: center;
    padding-bottom: 20PX;
    .sep {
      margin: 0 20PX;
    }
    a {
      color: #999999;
      text-decoration: none;
      }

  }
}