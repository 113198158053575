header {
    z-index: 999;
    position: fixed;
    top: 0;
    background: linear-gradient( 180deg, #000000 0%, rgba(0,0,0,0.5) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 18PX;
    color: #FFFFFF;
    letter-spacing: 1PX;
    width: 100%;
    padding: 10PX 40px 10PX 180px;
    box-sizing: border-box;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .route {
        display: flex;
        .item {
          margin-left: 80px;
          color: #FFFFFF;
          text-decoration: none;
          &:hover {
            color: #FF8634;
          }
          &:global(._active) {
            color: #FF8634;
          }
        }

      }
    }
    .right {
      display: flex;
      .contact {
        margin-right: 40PX;
        display: flex;
        align-items: center;
      }
      .login {
        padding: 10PX 20PX;
        border-radius: 8PX;
        border: 1PX solid #FFFFFF;
      }
    }
  }