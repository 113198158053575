.banner {
  background-image: url('~@/assets/img/about/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 1080px;
  text-align: center;
  padding: 180px 360px 0 360px;
  p {
    margin: 0;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32PX;
    color: #000000;
    line-height: 44PX;
    margin-bottom: 21PX;
  }
  div {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16PX;
    color: #000000;
    line-height: 30PX;
    letter-spacing: 1PX;
  }
}

  
.wedesc {
  width: 100%;
  height: 400px;
  box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.12);
  padding: 80px 450px  450px;
  p {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32PX;
    color: #000000;
    line-height: 44PX;
    text-align: center;
    margin-bottom: 80px;
  }
  :global(.bottom) {
    display: flex;
    justify-content: space-between;
    :global(.item) {
      :global(.num) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 46PX;
        color: #000000;
        line-height: 63PX;
        text-align: center;
        font-style: normal;
        margin-bottom: 10PX;

      }
      
      :global(.tip) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 14PX;
        color: #666666;
        line-height: 20PX;
      }
    }
    :global(.item.active) {
      :global(.num) {
        color: #FF8D32;
        text-shadow: 0px 2px 24px rgba(255,111,37,0.5);
      }
    }
  }
}

.concat_desc {
  background-color: #F1F3F6;
  padding: 80px 360px 80px 360px;
  text-align: center;
  p {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32PX;
    color: #000000;
    line-height: 44PX;
    margin-bottom: 60PX;
  }
  :global(.bottom) {
    display: flex;
  }
  :global(.item) {
    display: inline-block;
    padding: 54px 40px 66px 40px;
    flex-basis: 33.3333%;
    transition: all 0.5s;
    img {
      margin-bottom: 12PX;
    }
    :global(.icon){
      width: 44PX;
      height: 32PX;
      background: #ECEDEE;
      box-shadow: inset 0PX 0PX 8PX 0PX rgba(0,0,0,0.2);
      border-radius: 4PX;
    }
    :global(.tip){
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 14PX;
      color: #666666;
      line-height: 20PX;
      text-align: center;
    }
  }
  :global(.item.active) {
    background: radial-gradient( 84% 141% at 0% 0%, #FFF5F0 0%, #FFFEFA 47%, #FFFAFA 100%);
    border-radius: 12PX;
  }
}

.branches {
  background-color: #FFFFFF;
  padding: 80PX 360px 80PX 360px;
  p {
    margin: 0;
    margin-bottom: 60PX;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32PX;
    color: #000000;
    line-height: 44PX;
    text-align: center;
    font-style: normal;
  }
  :global(.row){
    display: flex;
    justify-content: space-between;

  }
  
  :global(.item){
    background: #FAFAFA;
    border: 1PX solid #C3C3C3;
    border-radius: 12PX;
    color: #000000;
    padding: 30PX 40PX 30PX 40PX;
    
    :global(.icon){
      font-weight: bold;
      font-size: 20PX;
    }
    :global(.tip){
      font-weight: 500;
      font-size: 16PX;
      color: #000000;
      line-height: 22PX;
      letter-spacing: 1PX;
      text-align: left;
      margin: 4PX 0 25PX 0;
    }
    :global(.tip_){
      font-weight: 500;
      font-size: 16PX;
      color: #000000;
      line-height: 22PX;
      letter-spacing: 1PX;
      text-align: left;
      margin: 4PX 0 5PX 0;
      width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    :global(.tip2){
      font-weight: 500;
      font-size: 16PX;
      color: #000000;
      line-height: 22PX;
      letter-spacing: 1PX;
      display: flex;
      align-items: center;
      :global(span) {
        display: inline-block;
        font-size: 14PX;
        font-weight: 500;
      }
      :global(.name){
        font-weight: bold;
        font-size: 16PX;
        color: #000000;
        line-height: 22PX;
        letter-spacing: 1PX;
        text-align: left;
      }
      :global(.line){
        width: 2PX;
        height: 16PX;
        background: #CCCCCC;
        margin: 0 16PX 0 16PX;
      }
    }
  }
  :global(.item.active){
    background: radial-gradient( 158% 131% at 20% 0%, #FFBB5E 0%, #FF866B 74%, #FF8634 100%);
    box-shadow: 0PX 4PX 24PX 0PX rgba(239,104,34,0.5);
    border: none;
    border-radius: 12PX;
    padding: 30PX 40PX 30PX 40PX;
    color: #FFFFFF;
    :global(.icon){
      font-weight: bold;
      font-size: 20PX;
    }
    :global(.tip){
      font-weight: 500;
      font-size: 16PX;
      color: #FFFFFF;
      line-height: 22PX;
      letter-spacing: 1PX;
      text-align: left;
      margin: 4PX 0 25PX 0;
    }
    :global(.tip_){
      font-weight: 500;
      font-size: 16PX;
      color: #FFFFFF;
      line-height: 22PX;
      letter-spacing: 1PX;
      text-align: left;
      margin: 4PX 0 5PX 0;
      width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    :global(.tip2){
      font-weight: 500;
      font-size: 16PX;
      color: #FFFFFF;
      line-height: 22PX;
      letter-spacing: 1PX;
      display: flex;
      align-items: center;
      :global(span) {
        display: inline-block;
        font-size: 14PX;
        font-weight: 500;
      }
      :global(.name){
        font-weight: bold;
        font-size: 16PX;
        color: #FFFFFF;
        line-height: 22PX;
        letter-spacing: 1PX;
        text-align: left;
      }
      :global(.line){
        width: 2PX;
        height: 16PX;
        background: #FFFFFF;
        margin: 0 16PX 0 16PX;
      }
    }
  }
}
